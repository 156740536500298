import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Accordion, Icon, Tab } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import MakeupStore from '../stores/MakeupStore';
import {DynamicTable} from '.';

const CustomAccItemTitle = styled(Accordion.Title)`
  display: inline-flex !important;
  width: 100% !important;
`
const CustomAccItemDropdownIcon = styled(Icon)`
  margin: auto 1% auto auto !important;
`

const CustomAccItemContainer = styled('div')`
  display: inline-flex !important;
`
const CustomAccItemIndicator = styled('img')`
  width: 40px !important;
  float: left !important;
  height: 40px !important;
  margin: auto !important;
`
const CustomAccItemHeadText = styled('div')`
  font-family: robo-reg !important;  
  margin:auto 10px auto 10px !important;
`

const CustomAccItemCarouselItemInfo = styled('div')`
  display: inline-flex !important;
  margin:auto 10px auto 10px !important;
`
const CustomAccItemCarouselItemInfoName = styled('div')`
  font-style: italic !important;
  font-family: robo-thin !important;
  font-size: 1.0rem !important;
  margin: auto !important;
`
const CustomAccItemCarouselItemInfoImage = styled('img')`
  width: auto !important;
  float: left !important;
  height: 45px !important;
  margin:auto 10px auto 10px !important;
`

const CustomAccContent = styled(Accordion.Content)`
  width: 100% !important;
`

const CustomAccordion = styled(Accordion)`
  width: 100% !important;
`

const CustomRow = styled('div')`
  max-height: 20vh;
  overflow-y: scroll;
`


const AccordionItem = (props) => {
  return(
    <CustomAccItemContainer>
      <CustomAccItemIndicator className='product-colo-menu-img' src={'/Assets/'+props.itemTitle.toLowerCase() +'.png'}></CustomAccItemIndicator>
      <CustomAccItemHeadText> TRY {props.itemTitle.toUpperCase()} </CustomAccItemHeadText>
      { props.carouselItem &&
        <CustomAccItemCarouselItemInfo>
            <CustomAccItemCarouselItemInfoName>
              {props.carouselItem.name}
            </CustomAccItemCarouselItemInfoName>
            { props.carouselItem.mask_url &&
              <CustomAccItemCarouselItemInfoImage src={props.carouselItem.mask_url}>
              </CustomAccItemCarouselItemInfoImage>
            }
        </CustomAccItemCarouselItemInfo>
      }
    </CustomAccItemContainer>
  )
}

const initialState = {

  activeIndex: 0,
  activeFoundationIndex: 0,
  activeLipIndex: 0,
  activeEyeIndex: 0,
  carouselItem: null

}
export class ProductsMenuGenerator extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
      this.setCarouselItem = this.setCarouselItem.bind(this);
    }

    handleFoundationTabsClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeFoundationIndex } = this.state;
      const newIndex = activeFoundationIndex === index ? -1 : index;
      this.setState({ activeFoundationIndex: newIndex });
    }

    handleLipsTabsClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeLipIndex } = this.state;
      const newIndex = activeLipIndex === index ? -1 : index;
      this.setState({ activeLipIndex: newIndex });
    }

    handleEyesTabsClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeEyeIndex } = this.state;
      const newIndex = activeEyeIndex === index ? -1 : index;
      this.setState({ activeEyeIndex: newIndex });
    }

    handleClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeIndex } = this.state;
      const newIndex = activeIndex === index ? -1 : index;
      this.setState({ activeIndex: newIndex });
    }

    setCarouselItem(item){
      this.setState({carouselItem:item});
    }

    componentDidMount(){
    }

    render(){
        
        const { activeFoundationIndex, activeLipIndex, activeEyeIndex, activeIndex } = this.state;


    const panes = [
      {
        menuItem: 'Foundation',
        pane: (
          <Tab.Pane key='foundation'>
            <CustomRow>
              <CustomAccordion fluid styled>
                <CustomAccItemTitle className={`${(activeFoundationIndex === 0) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeFoundationIndex === 0} index={0} onClick={this.handleFoundationTabsClick}>
                  <AccordionItem carouselItem={(activeFoundationIndex === 0)?this.state.carouselItem:null} itemTitle="Foundation"/>
                  <CustomAccItemDropdownIcon name='dropdown' />
                </CustomAccItemTitle>
                <CustomAccContent active={activeFoundationIndex === 0}>
                  <DynamicTable itemTitle="Foundation"/>
                </CustomAccContent>

                <CustomAccItemTitle className={`${(activeFoundationIndex === 1) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeFoundationIndex === 1} index={1} onClick={this.handleFoundationTabsClick}>
                  <AccordionItem carouselItem={(activeFoundationIndex === 1)?this.state.carouselItem:null} itemTitle="Foundation 2"/>
                  <CustomAccItemDropdownIcon name='dropdown' />
                </CustomAccItemTitle>
                <CustomAccContent active={activeFoundationIndex === 1}>
                  <DynamicTable itemTitle="Foundation 2"/>
                </CustomAccContent>
              </CustomAccordion>
            </CustomRow>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Lip',
        pane: (
          <Tab.Pane key='lip'>
          <CustomRow>
          <CustomAccordion fluid styled>
            <CustomAccItemTitle className={`${(activeLipIndex === 2) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeLipIndex === 2} index={2} onClick={this.handleLipsTabsClick}>
              <AccordionItem carouselItem={(activeLipIndex === 2)?this.state.carouselItem:null}  itemTitle="Lipstick"/>
              <CustomAccItemDropdownIcon name='dropdown' />
            </CustomAccItemTitle>
            <CustomAccContent active={activeLipIndex === 2}>
              <DynamicTable itemTitle="Lipstick"/>
            </CustomAccContent>

            <CustomAccItemTitle className={`${(activeLipIndex === 3) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeLipIndex === 3} index={3} onClick={this.handleLipsTabsClick}>
              <AccordionItem carouselItem={(activeLipIndex === 3)?this.state.carouselItem:null}  itemTitle="Lipliner"/>
              <CustomAccItemDropdownIcon name='dropdown' />
            </CustomAccItemTitle>
            <CustomAccContent active={activeLipIndex === 3}>
              <DynamicTable itemTitle="Lipliner"/>
            </CustomAccContent>
            </CustomAccordion>
            </CustomRow>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Eye',
        pane: (
          <Tab.Pane key='eye'>
          <CustomRow>
            <CustomAccordion fluid styled>
              <CustomAccItemTitle className={`${(activeEyeIndex === 1) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeEyeIndex === 1} index={1} onClick={this.handleEyesTabsClick}>
                <AccordionItem carouselItem={(activeEyeIndex === 1)?this.state.carouselItem:null}  itemTitle="Eyeliner"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeEyeIndex === 1}>
                <DynamicTable itemTitle="Eyeliner"/>
              </CustomAccContent>

              <CustomAccItemTitle className={`${(activeEyeIndex === 2) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeEyeIndex === 2} index={2} onClick={this.handleEyesTabsClick}>
                <AccordionItem carouselItem={(activeEyeIndex === 2)?this.state.carouselItem:null}  itemTitle="Eyelash"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeEyeIndex === 2}>
                <DynamicTable itemTitle="Eyelash"/>
              </CustomAccContent>

              <CustomAccItemTitle className={`${(activeEyeIndex === 3) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeEyeIndex === 3} index={3} onClick={this.handleEyesTabsClick}>
                <AccordionItem carouselItem={(activeEyeIndex === 3)?this.state.carouselItem:null}  itemTitle="Eyeshadow"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeEyeIndex === 3}>
                <DynamicTable itemTitle="Eyeshadow"/>
              </CustomAccContent>

              <CustomAccItemTitle className={`${(activeEyeIndex === 4) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeEyeIndex === 4} index={4} onClick={this.handleEyesTabsClick}>
                <AccordionItem carouselItem={(activeEyeIndex === 4)?this.state.carouselItem:null}  itemTitle="Eyebrow"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeEyeIndex === 4}>
                <DynamicTable itemTitle="Eyebrow"/>
              </CustomAccContent>
              </CustomAccordion>
            </CustomRow>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Others',
        pane: (
          <Tab.Pane key='tab5'>
          <CustomRow>
            <CustomAccordion fluid styled>
              <CustomAccItemTitle className={`${(activeIndex === 1) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 1} index={1} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 1)?this.state.carouselItem:null}  itemTitle="Blender"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeIndex === 1}>
                <DynamicTable itemTitle="Blender"/>
              </CustomAccContent>
            
              <CustomAccItemTitle className={`${(activeIndex === 4) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 4} index={4} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 4)?this.state.carouselItem:null}  itemTitle="Blush"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeIndex === 4}>
                <DynamicTable itemTitle="Blush"/>
              </CustomAccContent>

              <CustomAccItemTitle className={`${(activeIndex === 5) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 5} index={5} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 5)?this.state.carouselItem:null}  itemTitle="Highlighter"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeIndex === 5}>
                <DynamicTable itemTitle="Highlighter"/>
              </CustomAccContent>

              <CustomAccItemTitle className={`${(activeIndex === 6) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 6} index={6} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 6)?this.state.carouselItem:null}  itemTitle="Bronzer"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeIndex === 6}>
                <DynamicTable itemTitle="Bronzer"/>
              </CustomAccContent>

              <CustomAccItemTitle className={`${(activeIndex === 7) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 7} index={7} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 7)?this.state.carouselItem:null}  itemTitle="Contour"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
              <CustomAccContent active={activeIndex === 7}>
                <DynamicTable itemTitle="Contour"/>
              </CustomAccContent>



            </CustomAccordion>
            </CustomRow>
          </Tab.Pane>
        ),
      },
    ]

        return(
            <Tab panes={panes} renderActiveOnly={false} />
        );
    }
}
ProductsMenuGenerator = observer(ProductsMenuGenerator);
export default ProductsMenuGenerator;
