import * as React from 'react';
import styled from '@emotion/styled';
import AppStore from '../stores/AppStore';
import {observer} from 'mobx-react';
import {Container, Grid, Segment} from 'semantic-ui-react';
import {CategorySwitcher, ProductsMenu, ProductsMenuGenerator, LooksMenu, JSONControlView} from '.';
import ShoppingCart from './ShoppingCart';

const CustomSegmentGroup = styled(Segment.Group)`
    border: var(--color-pallet-container-border) !important;
    box-shadow: var(--color-pallet-container-box-shadow) !important;
`

const NoPaddingSegment = styled(Segment)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
` 

const NoPaddingSegment4Artist = styled(NoPaddingSegment)`
    width: 100% !important;
` 

const Container4Artist = styled(Container)`
     width: 100% !important;
` 

const initialState = {
    showProductPage : true,
}

class ColorPallet_Template extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState;
        this.tryProductHandler = this.tryProductHandler.bind(this);
        this.tryFullLookHandler = this.tryFullLookHandler.bind(this);
        this.resetAllMakeup = this.resetAllMakeup.bind(this);
    }

    resetAllMakeup(){
        window.vmt_module._resetAllMakeup();
        if(AppStore.currentPage === AppStore.PagesEnum.LIVE){
            window.SERVICES.vmtcore.renderLive();
        }
        else{
            window.SERVICES.vmtcore.render();
        }
    }

    tryProductHandler(){
        this.setState({showProductPage:true});
        this.resetAllMakeup();

    }
    tryFullLookHandler(){
        this.setState({showProductPage:false});
        this.resetAllMakeup();
    }

    render(){
        return(
        <Container>
            <CustomSegmentGroup>
                <NoPaddingSegment>
                    <CategorySwitcher
                    showProductPage={this.state.showProductPage}
                    tryProductHandler={this.tryProductHandler}
                    tryFullLookHandler={this.tryFullLookHandler}
                    />
                </NoPaddingSegment>
                {
                    this.state.showProductPage &&
                    <NoPaddingSegment><ProductsMenu/></NoPaddingSegment>
                }
                {
                    !this.state.showProductPage &&
                    <NoPaddingSegment><LooksMenu/></NoPaddingSegment>
                }
            </CustomSegmentGroup>
            {
                (AppStore.currentApp === AppStore.AppsEnum.BASIC) &&
                <ShoppingCart/>
            }
        </Container>
        );
    }
}

class ColorPallet_basic extends React.Component {
    render(){
        return(
        <ColorPallet_Template/>    
        );
    }
}

class ColorPallet_nudest extends React.Component {
    render(){
        return(
        <ColorPallet_Template/>    
        );
    }
}

class ColorPallet_artist extends React.Component {
    render(){
        return(
        <Container4Artist>
            <NoPaddingSegment4Artist>
                <Segment fluid textAlign='center'>
                    <JSONControlView/>
                </Segment>
                <ProductsMenuGenerator/>
            </NoPaddingSegment4Artist>
        </Container4Artist>
        );
    }
}

class ColorPallet extends React.Component {
    render(){
        if(AppStore.currentApp == AppStore.AppsEnum.BASIC) return(<ColorPallet_basic/>);
        if(AppStore.currentApp == AppStore.AppsEnum.NUDEST)return(<ColorPallet_nudest/>);
        if(AppStore.currentApp == AppStore.AppsEnum.ARTIST) return(<ColorPallet_artist/>);
    }
}

ColorPallet = observer(ColorPallet);
export {ColorPallet};