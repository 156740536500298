import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Button, Segment, Icon, Header, Modal } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import "react-responsive-modal/styles.css";
import { Modal as ConsentModal } from "react-responsive-modal";

const CustomContainer = styled(Segment)`
    margin: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    border: unset !important;
    box-shadow: unset !important;
    background-color: var(--selection-menu-bg) !important;

`
const CustomSegment = styled(Segment)`
    border: unset !important;
    box-shadow: unset !important;
    text-align: center !important;
    font-family: robo-reg !important;
    background: var(--selection-menu-bg) !important;
`
const CustomSegmentTitle = styled(CustomSegment)`
    padding: 5px 20px !important;
`
const CustomSegmentSubtitle = styled(CustomSegment)`
    padding: 5px 20px !important;
`
const CustomHeaderTitle = styled(Header)`
    font-family: robo-reg; !important;
    font-size: 3.5rem !important;
    font-weight: unset !important;
    color: var(--app-container-heading) !important;
    @media (max-width: 1440px) {
        font-size: 2.8rem !important;
    }
    @media (max-width: 1210px) {
        font-size: 2.4rem !important;
    }
    @media (max-width: 425px) {
        font-size: 1.6rem !important;
    }
`
const CustomHeaderSubtitle = styled(Header)`
    font-size: 2.4rem !important;
    font-family: robo-reg !important;
    font-weight: unset !important;
    color: var(--app-container-sub-heading) !important;
    @media (max-width: 1440px) {
        font-size: 2.0rem !important;
    }
    @media (max-width: 1210px) {
        font-size: 1.8rem !important;
    }
    @media (max-width: 425px) {
        font-size: 1.3rem !important;
    }
`
const CustomSegmentButtonGroup = styled(CustomSegment)`
    display: block !important;
    background: var(--selection-menu-bg);
`
const CustomSegmentDisclaimer = styled(CustomSegment)`
    font-family: robo-reg !important;
    font-weight: unset !important;
    font-size: 1.5rem !important;
    font-style: italic;
    color: var(--app-container-disclaimer) !important;
    background: var(--selection-menu-bg);

    @media (max-width: 1440px) {
        font-size: 1.2rem !important;
    }
    @media (max-width: 1210px) {
        font-size: 1.0rem !important;
    }
    @media (max-width: 425px) {
        font-size: 0.8rem !important;
    }
`
const CustomSegmentGroup = styled(Segment.Group)`
    border: unset !important;
    box-shadow: unset !important;
`
const ImageElm = styled('img')`
    height: 30px !important;
    width: 30px !important;
    opacity: 0.9 !important;
    margin: auto auto auto 10px !important;
`
const ColorCorrectedImageCSS = styled(ImageElm)`
    opacity: 0.6 !important;
`

const ButtonContent = styled('div')`
    display: flex;
`

const ButtonLabel = styled('div')`
    font-family: robo-reg !important;
    margin: auto 10px auto auto !important;
    font-size: 1.6rem !important;
    padding: 6px;
    @media (max-width: 1440px) {
        font-size: 1.3rem !important;
    }
    @media (max-width: 1210px) {
        font-size: 1.0rem !important;
    }
    @media (max-width: 425px) {
        font-size: 0.8rem !important;
    }
`

const CustomButton = styled(Button)`
    display: block !important;
    border-radius: unset !important;
    margin: 15px auto !important;
    width: 300px !important;
    color: var(--selection-menu-btn-txt-A) !important;
    background: var(--selection-menu-btn-bg) !important;
    &:hover{
        color: var(--selection-menu-btn-txt-B) !important;
        background: var(--selection-menu-btn-bg-hover) !important;
    }

`
const CustomModelImage = styled('img')`
    height: 110px;
    margin: 25px;
    border-radius: 10px;
    cursor: pointer;
    &:hover{
        opacity: 0.55;
    }
`

const InputForUploadPicture = styled('input')`
    display: none !important;
`
const ModalContainer = styled(Modal)`
    background: var(--choose-model-bg) !important;
`
const ModalHeader = styled(Header)`
    border: unset !important;
`
const ModalActions = styled(Modal.Actions)`
    border: unset !important;
`
const ModalExitBtn = styled(Button)`
    color: var(--choose-model-return-txt-A) !important;
    background: var(--choose-model-return-bg) !important;
    box-shadow: unset !important;
    &:hover{
        color: var(--choose-model-return-txt-B) !important;
        background: var(--choose-model-return-bg-hover) !important;
    }
`

const CameraButtonContent = (props) => {
    return(
        <ButtonContent>
        {
            props.showLive &&
            <ButtonLabel>Live Makeup</ButtonLabel>
        }
        {
            !props.showLive &&
            <ButtonLabel>For live, use Safari</ButtonLabel>
        }
        <ImageElm className="selection-menu-btn-img-icons" src="/Assets/camera-icon.png"></ImageElm>
    </ButtonContent>
    )
}

const PictureUploadButtonContent = () => {
    return(
        <ButtonContent>
            <ButtonLabel>Use photo</ButtonLabel>
            <ColorCorrectedImageCSS className="selection-menu-btn-img-icons" src="/Assets/upload-icon.png"></ColorCorrectedImageCSS>
        </ButtonContent>
    )
}

const ChooseModelButtonContent = () => {
    return(
        <ButtonContent>
            <ButtonLabel>Choose a model</ButtonLabel>
            <ImageElm className="selection-menu-btn-img-icons" src="/Assets/model-icon.png"></ImageElm>
        </ButtonContent>
    )
}

class ModelSelection extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        return(
        <ModalContainer onClose={(evt) => this.props.closePortal(-1)} open={this.props.showPortal} basic size='small'>
            <ModalHeader className="modal-header" icon='hand pointer outline' content='Choose a Model' inverted color='grey' />
            <Modal.Content>
                    <CustomModelImage onClick={ (evt) => this.props.closePortal(1)} src="/Models/1.jpg"/>
                    <CustomModelImage onClick={ (evt) => this.props.closePortal(2)} src="/Models/2.jpg"/>
                    <CustomModelImage onClick={ (evt) => this.props.closePortal(3)} src="/Models/3.jpg"/>
                    <CustomModelImage onClick={ (evt) => this.props.closePortal(4)} src="/Models/4.jpg"/>
            </Modal.Content>
            <ModalActions>
                <ModalExitBtn color='red' onClick={(evt) => this.props.closePortal(-1)}>
                    <Icon name='close' /> Go Back
                </ModalExitBtn>
            </ModalActions>
        </ModalContainer>
        )
    }

}


/**
 *********************************************
 ***    TEMPLATE
 *********************************************
 */
class SelectionMenu_Template extends React.Component {
    state = {
        openUpload: false,
        openLive:false,
        openModel:false
    };
  
    onOpenModalUpload = () => {
      this.setState({openUpload: true, openLive: false, openModel: false});
    };
  
    onCloseModalUpload = () => {
      this.setState({openUpload: false, openLive: false, openModel: false});
    };
    onOpenModalLive = () => {
      this.setState({openLive: true, openUpload: false, openModel: false});
    };
  
    onCloseModalLive = () => {
      this.setState({openLive: false, openUpload: false, openModel: false});
    };
    
    onOpenModalModel = () => {
      this.setState({openModel: true, openUpload: false, openLive: false});
    };
  
    onCloseModalModel = () => {
      this.setState({openModel: false, openUpload: false, openLive: false});
    };
    constructor(props){
        super(props);

        this.state = {
            showPortal: false,
            showLive: true,
        };

        this.handleLiveOpen = this.handleLiveOpen.bind(this);
        this.handleUploadOpen = this.handleUploadOpen.bind(this);
        this.handleModelOpen = this.handleModelOpen.bind(this);
        this.handleModelFinished = this.handleModelFinished.bind(this);
        this.handleUploadImageChange = this.handleUploadImageChange.bind(this);
    }
    handleUploadImageChange(selectedFiles){
        var reader = new FileReader();
        if(selectedFiles === null || !selectedFiles[0]) return;
        reader.readAsDataURL(selectedFiles[0]);
        reader.onload = function() {
            if(typeof reader.result != 'string') return;
            let image = new Image;
            image.src = reader.result;
            image.onload = function() {
                let canvas = document.createElement("canvas");
                canvas.setAttribute('id', 'canvas_downsize');
                let aspect = window.calculateAspectRatioFit(image.width, image.height, 1000, 1200)
                console.log("[INFO] image actual width = ", image.width, " image height = ", image.height);
                console.log("[INFO] image width scaled by = ", (aspect.width / image.width).toFixed(2), "X and height by = ", (aspect.height / image.height).toFixed(2), "X");
                if(window.navigator.isIPhone){
                    console.log("[INFO] IPhone detected default rotation procedure called");
                    canvas.width = image.height;
                    canvas.height = image.width;
                    let ctx = canvas.getContext("2d");
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.save();
                    ctx.translate(canvas.width/2, canvas.height/2);
                    ctx.rotate(Math.PI / 2 );
                    ctx.drawImage(image, -image.width / 2, -image.height / 2);
                    ctx.restore();
                    window.hermiteFilterResize(canvas, aspect.height, aspect.width, true);
                }
                else{
                    canvas.width = aspect.width;
                    canvas.height = aspect.height;
                    let ctx = canvas.getContext("2d");
                    ctx.scale(aspect.width / image.width, aspect.height / image.height);
                    ctx.drawImage(image, 0, 0);
                    window.hermiteFilterResize(canvas, aspect.width, aspect.height, true);
                }

                AppStore.setUploadedImage(canvas.toDataURL('image/jpeg'));
                AppStore.setCurrentPage(AppStore.PagesEnum.PHOTO);
            }
        }
    }

    handleLiveOpen(){
        AppStore.setCurrentPage(AppStore.PagesEnum.LIVE);
    }
    handleUploadOpen(){
        document.getElementById('input-elm-for-upload-picture').click();
    }
    handleModelOpen(){
        if(AppStore.currentApp==AppStore.AppsEnum.ARTIST){
            if(window.outerWidth > 1024){
                AppStore.setCurrentPage(AppStore.PagesEnum.MODEL);
                return;
            }else{
                if(this.state.showPortal) this.setState({showPortal:false});
                if(!this.state.showPortal) this.setState({showPortal:true});
            }
        }
        else{
            if(this.state.showPortal) this.setState({showPortal:false});
            if(!this.state.showPortal) this.setState({showPortal:true});
        }
    }

    componentDidMount(){
        if(navigator.isIOS){
            if(navigator.userAgent.match('CriOS')){
                //is not safari
                var self = this;
                setTimeout(()=>{
                    self.setState({showLive:false});
                },100);
            }
        }
    }

    handleModelFinished(m_idx){
        this.setState({showPortal:false});
        if(m_idx >= 1 && m_idx <= 4){
          AppStore.setSelectedModel(m_idx);
          AppStore.setCurrentPage(AppStore.PagesEnum.MODEL);
        }
    }


    render(){
        const { openUpload, openLive, openModel } = this.state;
        return(
            <CustomContainer>
                <div>
                    <div>
                    <ConsentModal open={openUpload} onClose={this.onCloseModalUpload} center>
                        <div className="consent-text">I am age eighteen (18) or older and consent to the processing of my photo and/or image by AlgoFace for the purpose of this demo.
                        </div>
                        <div className="consent-button">
                            <button className="consent green" onClick={this.handleUploadOpen}>I Consent</button>
                        </div>
                    </ConsentModal>
                    </div>
                    <div>
                    <ConsentModal open={openLive} onClose={this.onCloseModalLive} center>
                        <div className="consent-text">I am age eighteen (18) or older and consent to the processing of my photo and/or image by AlgoFace for the purpose of this demo.
                        </div>
                        <div className="consent-button">
                            <button className="consent green" onClick={this.handleLiveOpen}>I Consent</button>
                        </div>
                    </ConsentModal>
                    </div>
                    <div>
                    <ConsentModal open={openModel} onClose={this.onCloseModalModel} center>
                        <div className="consent-text">I am age eighteen (18) or older and consent to the processing of my photo and/or image by AlgoFace for the purpose of this demo.
                        </div>
                        <div className="consent-button">
                            <button className="consent green" onClick={this.handleModelOpen}>I Consent</button>
                        </div>
                    </ConsentModal>
                    </div>
                </div>
                <InputForUploadPicture type="file" id="input-elm-for-upload-picture" onChange={(e) => this.handleUploadImageChange(e.target.files)} accept="image/jpeg, image/png" />
                <ModelSelection showPortal={this.state.showPortal} closePortal={this.handleModelFinished}/>
                <CustomSegmentGroup>
                    {
                        this.props.sm_title &&
                        <CustomSegmentTitle centered textAlign="center"><CustomHeaderTitle as='div'>{this.props.sm_title}</CustomHeaderTitle></CustomSegmentTitle>
                    }
                    {
                        this.props.sm_subtitle &&
                        <CustomSegmentSubtitle centered textAlign="center"><CustomHeaderSubtitle as='div'>{this.props.sm_subtitle}</CustomHeaderSubtitle></CustomSegmentSubtitle>
                    }
                    <CustomSegmentButtonGroup>
                        {
                            this.props.sm_show_live &&
                            <CustomButton fluid onClick={this.onOpenModalLive} disabled={!this.state.showLive}><CameraButtonContent showLive={this.state.showLive}/></CustomButton>
                        }
                        {
                            this.props.sm_show_upload &&
                            <CustomButton fluid onClick={this.onOpenModalUpload}><PictureUploadButtonContent/></CustomButton>
                        }
                        {
                            this.props.sm_show_model &&
                            <CustomButton fluid onClick={this.onOpenModalModel}><ChooseModelButtonContent/></CustomButton>
                        }
                    </CustomSegmentButtonGroup>
                </CustomSegmentGroup>
            </CustomContainer>
        );
    }
}


/**
 *********************************************
 ***    BASIC
 *********************************************
 */
class SelectionMenu_basic extends React.Component {
    render(){
        return(
            <SelectionMenu_Template
                sm_title="Virtual Makeup AR-tist"
                sm_subtitle="Try Before You Buy"
                sm_show_live={true}
                sm_show_upload={true}
                sm_show_model={true}
            />
        );
    }
}

/**
 *********************************************
 ***    NUDEST
 *********************************************
 */
class SelectionMenu_nudest extends React.Component {
    render(){
        return(
            <SelectionMenu_Template
                sm_title="Virtual Makeup AR-tist"
                sm_subtitle="Try Before You Buy"
                sm_show_live={true}
                sm_show_upload={true}
                sm_show_model={false}
            />
        );
    }
}

/**
 *********************************************
 ***    ARTIST
 *********************************************
 */
class SelectionMenu_artist extends React.Component {
    render(){
        return(
            <SelectionMenu_Template
                sm_title="Product Catalogue"
                sm_subtitle="Create Your Virtual SKU's"
                sm_show_live={true}
                sm_show_upload={true}
                sm_show_model={true}
            />
        );
    }
}

class SelectionMenu extends React.Component {
    render(){
        if(AppStore.currentApp == AppStore.AppsEnum.BASIC) return(<SelectionMenu_basic/>);
        if(AppStore.currentApp == AppStore.AppsEnum.NUDEST)return(<SelectionMenu_nudest/>);
        if(AppStore.currentApp == AppStore.AppsEnum.ARTIST) return(<SelectionMenu_artist/>);
    }
}

SelectionMenu = observer(SelectionMenu);
export {SelectionMenu};
