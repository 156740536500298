import { decorate, observable, configure, action, computed } from "mobx";

class ProductStore {
  MakeupStore = observable({
    foundation: [],
    blender: [],
    blush: [],
    highlighter: [],
    lipliner: [],
    bronzer: [],
    contour: [],
    lipstick: [],
    eyeshadow: [],
    eyelash: [],
    eyeliner: [],
    eyebrow: [],
    "foundation 2": [],
  });

  addProduct(
    sku_id = undefined,
    sku_name = undefined,
    type = undefined,
    r = undefined,
    g = undefined,
    b = undefined,
    a = undefined,
    finish_intensity = undefined,
    finish_type = undefined,
    glitter_mask = undefined,
    glitter_intensity = undefined,
    shimmer_mask = undefined,
    shimmer_intensity = undefined,
    mask = undefined,
    smoothing_coefficent = undefined,
    shininess = undefined,
    blending_ratio = null,
    mask_intensity = null,
    shimmer_r = undefined,
    shimmer_g = undefined,
    shimmer_b = undefined,
    shimmer_a = undefined
  ) {
    let product = {
      idx: undefined,
      sku_id: undefined,
      sku_name: undefined,
      type: undefined,
    };
    let productFeature = {
      idx: undefined,
      type: undefined,
      r: undefined,
      g: undefined,
      b: undefined,
      a: undefined,
      finish_intensity: undefined,
      finish_type: undefined,
      glitter_mask: undefined,
      glitter_intensity: undefined,
      shimmer_mask: undefined,
      shimmer_intensity: undefined,
      mask: undefined,
      smoothing_coefficent: undefined,
      shininess: undefined,
      blending_ratio: undefined,
      mask_intensity: undefined,
      shimmer_r: undefined,
      shimmer_g: undefined,
      shimmer_b: undefined,
      shimmer_a: undefined,
    };

    var allProducts = this.MakeupStore[type.toLowerCase()];

    product["idx"] = allProducts.length;
    product["sku_id"] = sku_id;
    product["sku_name"] = sku_name;
    product["type"] = type;

    productFeature["idx"] = 0;
    productFeature["sku_name"] = sku_name;
    productFeature["sku_id"] = type + "_" + allProducts.length + "_" + 0;
    productFeature["type"] = type;
    productFeature["r"] = r;
    productFeature["g"] = g;
    productFeature["b"] = b;
    productFeature["a"] = a;
    productFeature["finish_intensity"] = finish_intensity;
    productFeature["finish_type"] = finish_type;
    productFeature["glitter_mask"] = glitter_mask;
    productFeature["glitter_intensity"] = glitter_intensity;
    productFeature["shimmer_mask"] = shimmer_mask;
    productFeature["shimmer_intensity"] = shimmer_intensity;
    productFeature["mask"] = mask;
    productFeature["smoothing_coefficent"] = smoothing_coefficent;
    productFeature["shininess"] = shininess;
    productFeature["blending_ratio"] = blending_ratio;
    productFeature["mask_intensity"] = mask_intensity;
    productFeature["shimmer_r"] = shimmer_r;
    productFeature["shimmer_g"] = shimmer_g;
    productFeature["shimmer_b"] = shimmer_b;
    productFeature["shimmer_a"] = shimmer_a;
    product["product_features"] = [];

    product["product_features"].push(productFeature);

    if (product["type"] === undefined) {
      window.SERVICES.notify(`unable to add product, try again`, "ERR");
      return;
    } else {
      this.MakeupStore[product["type"].toLowerCase()].push(product);
      window.SERVICES.notify(`${sku_name} (product) added`);
    }
  }

  addProductFeature(
    idx = undefined,
    sku_name = undefined,
    type = undefined,
    r = undefined,
    g = undefined,
    b = undefined,
    a = undefined,
    finish_intensity = undefined,
    finish_type = undefined,
    glitter_mask = undefined,
    glitter_intensity = undefined,
    shimmer_mask = undefined,
    shimmer_intensity = undefined,
    mask = undefined,
    smoothing_coefficent = undefined,
    shininess = undefined,
    blending_ratio = undefined,
    mask_intensity = undefined,
    shimmer_r = undefined,
    shimmer_g = undefined,
    shimmer_b = undefined,
    shimmer_a = undefined,
  ) {
    let product = this.MakeupStore[type.toLowerCase()][idx];

    let productFeature = {
      idx: undefined,
      sku_name: undefined,
      type: undefined,
      r: undefined,
      g: undefined,
      b: undefined,
      a: undefined,
      finish_intensity: undefined,
      finish_type: undefined,
      glitter_mask: undefined,
      glitter_intensity: undefined,
      shimmer_mask: undefined,
      shimmer_intensity: undefined,
      mask: undefined,
      smoothing_coefficent: undefined,
      shininess: undefined,
      blending_ratio: undefined,
      mask_intensity: undefined,
      shimmer_r: undefined,
      shimmer_g: undefined,
      shimmer_b: undefined,
      shimmer_a: undefined,
    };

    var allProducts = this.MakeupStore[type.toLowerCase()];

    productFeature["idx"] = product.product_features
      ? product.product_features.length
      : 0;
    productFeature["sku_id"] =
      type + "_" + idx + "_" + product?.product_features?.length;
    productFeature["sku_name"] = sku_name;
    productFeature["type"] = type;
    productFeature["r"] = r;
    productFeature["g"] = g;
    productFeature["b"] = b;
    productFeature["a"] = a;
    productFeature["finish_intensity"] = finish_intensity;
    productFeature["finish_type"] = finish_type;
    productFeature["glitter_mask"] = glitter_mask;
    productFeature["glitter_intensity"] = glitter_intensity;
    productFeature["shimmer_mask"] = shimmer_mask;
    productFeature["shimmer_intensity"] = shimmer_intensity;
    productFeature["mask"] = mask;
    productFeature["smoothing_coefficent"] = smoothing_coefficent;
    productFeature["shininess"] = shininess;
    productFeature["blending_ratio"] = blending_ratio;
    productFeature["mask_intensity"] = mask_intensity;
    productFeature["shimmer_r"] = shimmer_r;
    productFeature["shimmer_g"] = shimmer_g;
    productFeature["shimmer_b"] = shimmer_b;
    productFeature["shimmer_a"] = shimmer_a;

    product["product_features"].push(productFeature);

    if (product["type"] === undefined) {
      window.SERVICES.notify(`unable to add product feature, try again`, "ERR");
      return;
    } else {
      this.MakeupStore[product["type"].toLowerCase()][product.idx] = product;
      window.SERVICES.notify(`${product.sku_name} (product) feature added`);
    }
  }

  cloneProduct(idx = undefined, type = undefined) {
    let newList = [];
    let sku_name = "";

    for (let i = 0; i < this.MakeupStore[type.toLowerCase()].length; i++) {
      var product = this.MakeupStore[type.toLowerCase()][i];

      sku_name = product.sku_name;
      product.idx = newList.length;
      newList.push({ ...product });

      if (i === idx) {
        product["idx"] = newList.length;
        var prd = JSON.stringify(product);
        newList.push(JSON.parse(prd));
      }
    }

    this.MakeupStore[type.toLowerCase()] = newList;
    window.SERVICES.notify(`${sku_name} (product) Cloned.`);
  }

  updateProductFeature(
    idx = undefined,
    feature_idx = undefined,
    sku_id = undefined,
    sku_name = undefined,
    type = undefined,
    r = undefined,
    g = undefined,
    b = undefined,
    a = undefined,
    finish_intensity = undefined,
    finish_type = undefined,
    glitter_mask = undefined,
    glitter_intensity = undefined,
    shimmer_mask = undefined,
    shimmer_intensity = undefined,
    mask = undefined,
    smoothing_coefficent = undefined,
    shininess = undefined,
    blending_ratio = undefined,
    mask_intensity = undefined,
    shimmer_r = undefined,
    shimmer_g = undefined,
    shimmer_b = undefined,
    shimmer_a = undefined
  ) {
    var product = { idx, type };

    if (product["type"] === undefined) {
      window.SERVICES.notify(`unable to update product, try again`, "ERR");
      return;
    } else {
      let productFeature =
        this.MakeupStore[product["type"].toLowerCase()][product.idx][
          "product_features"
        ][feature_idx];

      // product["idx"] = idx;
      // product["sku_id"] = sku_id;
      productFeature["sku_id"] = sku_id;
      productFeature["sku_name"] = sku_name;
      productFeature["type"] = type;
      productFeature["r"] = r;
      productFeature["g"] = g;
      productFeature["b"] = b;
      productFeature["a"] = a;
      productFeature["finish_intensity"] = finish_intensity;
      productFeature["finish_type"] = finish_type;
      productFeature["glitter_mask"] = glitter_mask;
      productFeature["glitter_intensity"] = glitter_intensity;
      productFeature["shimmer_mask"] = shimmer_mask;
      productFeature["shimmer_intensity"] = shimmer_intensity;
      productFeature["mask"] = mask;
      productFeature["smoothing_coefficent"] = smoothing_coefficent;
      productFeature["shininess"] = shininess;
      productFeature["blending_ratio"] = blending_ratio;
      productFeature["mask_intensity"] = mask_intensity;
      productFeature["shimmer_r"] = shimmer_r;
      productFeature["shimmer_g"] = shimmer_g;
      productFeature["shimmer_b"] = shimmer_b;
      productFeature["shimmer_a"] = shimmer_a;

      this.MakeupStore[product["type"].toLowerCase()][product.idx][
        "product_features"
      ][feature_idx] = productFeature;
      window.SERVICES.notify(`${sku_name} (product) Updated`);
    }
  }

  updateProduct(
    idx = undefined,
    sku_id = undefined,
    sku_name = undefined,
    type = undefined,
    r = undefined,
    g = undefined,
    b = undefined,
    a = undefined,
    finish_intensity = undefined,
    finish_type = undefined,
    glitter_mask = undefined,
    glitter_intensity = undefined,
    shimmer_mask = undefined,
    shimmer_intensity = undefined,
    mask = undefined,
    smoothing_coefficent = undefined,
    shininess = undefined,
    blending_ratio = undefined,
    mask_intensity = undefined,
    shimmer_r = undefined,
    shimmer_g = undefined,
    shimmer_b = undefined,
    shimmer_a = undefined
  ) {
    let product = {
      idx: undefined,
      sku_id: undefined,
      sku_name: undefined,
      type: undefined,
      product_features: [],
    };
    let productFeature = {
      idx: undefined,
      sku_name: undefined,
      type: undefined,
      r: undefined,
      g: undefined,
      b: undefined,
      a: undefined,
      finish_intensity: undefined,
      finish_type: undefined,
      glitter_mask: undefined,
      glitter_intensity: undefined,
      shimmer_mask: undefined,
      shimmer_intensity: undefined,
      mask: undefined,
      smoothing_coefficent: undefined,
      shininess: undefined,
      blending_ratio: undefined,
      mask_intensity: undefined,
      shimmer_r: undefined,
      shimmer_g: undefined,
      shimmer_b: undefined,
      shimmer_a: undefined,
    };
    product["idx"] = idx;
    product["sku_id"] = sku_id;
    product["sku_name"] = sku_name;
    product["type"] = type;

    productFeature["sku_name"] = sku_name;
    productFeature["type"] = type;
    productFeature["r"] = r;
    productFeature["g"] = g;
    productFeature["b"] = b;
    productFeature["a"] = a;
    productFeature["finish_intensity"] = finish_intensity;
    productFeature["finish_type"] = finish_type;
    productFeature["glitter_mask"] = glitter_mask;
    productFeature["glitter_intensity"] = glitter_intensity;
    productFeature["shimmer_mask"] = shimmer_mask;
    productFeature["shimmer_intensity"] = shimmer_intensity;
    productFeature["mask"] = mask;
    productFeature["smoothing_coefficent"] = smoothing_coefficent;
    productFeature["shininess"] = shininess;
    productFeature["blending_ratio"] = blending_ratio;
    productFeature["mask_intensity"] = mask_intensity;
    productFeature["shimmer_r"] = shimmer_r;
    productFeature["shimmer_g"] = shimmer_g;
    productFeature["shimmer_b"] = shimmer_b;
    productFeature["shimmer_a"] = shimmer_a;

    product["product_features"][0] = productFeature;

    if (productFeature["type"] === undefined) {
      window.SERVICES.notify(`unable to update product, try again`, "ERR");
      return;
    } else {
      this.MakeupStore[productFeature["type"].toLowerCase()][product.idx] =
        product;
      window.SERVICES.notify(`${sku_name} (product) Updated`);
    }
  }

  getProductsByType(type) {
    return this.MakeupStore[type];
  }

  getProductListLenByType(type) {
    return this.MakeupStore[type].length;
  }

  removeProduct(skuid, type, prd_idx, feature_idx) {
    try {
      if (prd_idx >= 0 && feature_idx >= 0) {
        this.MakeupStore[type.toLowerCase()][prd_idx][
          "product_features"
        ].splice(feature_idx, 1);
      } else {
        let idx = this.MakeupStore[type.toLowerCase()].findIndex(
          (item) => item["sku_id"] === skuid
        );
        if (idx != -1) {
          this.MakeupStore[type.toLowerCase()].splice(idx, 1);
        }
      }
    } catch (e) {
      window.SERVICES.notify(`unable to remove product, try again`, "ERR");
    }
  }

  get allProducts() {
    return this.MakeupStore;
  }

  loadFromJSON(objstr) {
    var self = this;
    try {
      var makeupStoreObj = JSON.parse(objstr);
      Object.keys(makeupStoreObj).forEach((lbl) => {
        if (lbl) {
          self.MakeupStore[lbl.toLowerCase()].splice(
            0,
            self.MakeupStore[lbl.toLowerCase()].length
          );
          for (let i = 0; i < makeupStoreObj[lbl].length; i++) {
            self.MakeupStore[lbl.toLowerCase()].push(makeupStoreObj[lbl][i]);
          }
        }
      });
    } catch (error) {
      window.SERVICES.notify(`unable to load file, try again`, "ERR");
    }
  }

  getJSON() {
    return JSON.stringify(this.MakeupStore, null, 4);
  }
}

decorate(ProductStore, {
  getProductsByType: action,
  getProductListLenByType: action,
  allProducts: computed,
  addProduct: action,
  removeProduct: action,
  loadFromJSON: action,
  getJSON: action,
});

export default new ProductStore();
